import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Formik, FormikHelpers } from 'formik'
import React, { ReactNode } from 'react'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import { RcCompletable } from './RcInlineEdit'
import { StatusTextField } from './StatusTextField'
import { useApiError } from '@/core/hooks/useApiError'

export interface RcTextInputProps<T> extends RcCompletable {
  property: keyof T
  source: T | undefined
  update: (value: T) => Promise<any>
  label?: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  startIcon?: IconProp
  required?: boolean
  url?: boolean
  email?: boolean
  number?: boolean
  json?: boolean
  multiline?: boolean
  max?: number
  autoFocus?: boolean
  defaultValue?: any
  placeholder?: string
  isSubmitting?: boolean
  info?: React.ReactNode
}

export const RcTextInput = <T extends Record<string, any>>({
  property,
  source,
  update,
  description,
  disabled,
  label,
  startIcon,
  required,
  email,
  url,
  number,
  json,
  multiline,
  max,
  onComplete,
  autoFocus,
  placeholder,
  isSubmitting: externalIsSubmitting,
  defaultValue = null,
  info
}: RcTextInputProps<T> & { children?: ReactNode }) => {
  const { handle } = useApiError()

  let val = number ? Yup.number() : Yup.string()

  if (url) {
    val = (val as Yup.StringSchema<string>).url()
  }

  if (email) {
    val = (val as Yup.StringSchema<string>).email()
  }
  if (json) {
    val = (val as Yup.StringSchema<string>).test(
      'isJson',
      'Value is not a valid JSON string',
      (str: string) => {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      }
    )
  }

  const validation = Yup.object().shape({
    [property]: val
  })

  const submit = async (values: any, helpers: FormikHelpers<any>) => {
    // allow clearing by typing nothing.
    if (values[property] === '') values[property] = defaultValue

    try {
      await update(values)
    } catch (error) {
      await handle(error, { values, helpers, field: property.toString() })
    } finally {
      onComplete?.()
    }

    return Promise.resolve()
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{ [property]: source?.[property] || '' }}
      validationSchema={validation}
      onSubmit={submit}
    >
      {({
        submitForm,
        values,
        dirty,
        getFieldMeta,
        resetForm,
        isSubmitting
      }) => (
        <StatusTextField
          fullWidth
          name={property}
          type={number ? 'number' : 'text'}
          variant='outlined'
          label={label}
          description={description}
          value={(values as T)[property]}
          required={required}
          dirty={dirty}
          disabled={disabled}
          saving={isSubmitting || externalIsSubmitting}
          error={getFieldMeta(property.toString()).error}
          inputPropBlur={submitForm}
          onCancel={onComplete ? onComplete : resetForm}
          startIcon={startIcon}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          max={max}
          autoFocus={autoFocus}
          onFocus={(event: any) =>
            autoFocus ? event.target.select() : undefined
          }
          info={info}
          placeholder={placeholder}
        />
      )}
    </Formik>
  )
}
