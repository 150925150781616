'use client'

import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Field, FieldAttributes, useFormikContext } from 'formik'
import { Select } from 'formik-mui'
import { Children } from 'react'

import { CharacterCount } from '@/components/molecules/text/CharacterCount'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useIsMobileDevice } from '@/core/hooks/useMediaQueries'
import { useScrollToError } from '@/core/hooks/useScrollToError'

export const LabeledField: React.FC<FieldAttributes<any>> = ({
  label,
  description,
  info,
  placeholder,
  icon,
  InputProps,
  required,
  ...props
}) => {
  const isMobileDevice = useIsMobileDevice()
  const { values, errors } = useFormikContext<any>()

  useScrollToError(errors, 'Mui-error')

  if (isMobileDevice && props.component === Select) {
    props.native = true
    props.children = Children.toArray(props.children).map((it: any) => (
      <option key={it.key} {...it.props} />
    ))
  }

  return (
    <Box>
      <LabeledFieldHeader
        label={label}
        description={description}
        info={info}
        required={required}
      />
      {props.component === Select ? (
        <Field placeholder={placeholder} {...props} />
      ) : (
        <Field
          placeholder={placeholder}
          InputProps={{
            placeholder: placeholder,
            startAdornment: icon ? (
              <InputAdornment position='start'>{icon}</InputAdornment>
            ) : null,
            ...InputProps
          }}
          {...props}
        />
      )}

      {errors[props.name] || !props.max ? null : (
        <CharacterCount value={values[props.name]} max={props.max} />
      )}
    </Box>
  )
}

export const LabeledFieldHeader = ({
  label,
  description,
  required,
  info,
  extra
}: {
  label: React.ReactNode
  description?: React.ReactNode
  required?: boolean
  info?: React.ReactNode
  extra?: React.ReactNode
}) => {
  const showHeader = !!label || !!description
  return showHeader ? (
    <Stack direction='column' spacing={2} sx={{ mb: 2 }}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography variant='subtitle1' color='text.secondary'>
          {label}
        </Typography>
        {required ? (
          <Typography variant='subtitle1' color='text.secondary'>
            *
          </Typography>
        ) : null}
        {info ? (
          <Tooltip enterDelay={0} arrow placement='top' title={info}>
            <span>
              <RcIcon icon={['fal', 'info-circle']} color='info' />
            </span>
          </Tooltip>
        ) : null}
        {extra}
      </Stack>
      {description ? (
        <Typography variant='subtitle2' color='text.secondary'>
          {description}
        </Typography>
      ) : null}
    </Stack>
  ) : null
}
